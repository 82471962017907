import { Component, OnInit } from '@angular/core';
import { AdminService } from '@app/core/services/admin.service'; 
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { ChangeDetectorRef } from '@angular/core';
import { TMoocData, TLections, TObjetivo, TResources, TLectionObjetive, TActivity, Tquestion, Answers } from "./form.types";

import Swal from 'sweetalert2'



@Component({
  selector: "mooc-form-mooc",
  templateUrl: "./form-mooc.component.html",
  styleUrls: ["./form-mooc.component.scss"],
})
export class FormMoocComponent implements OnInit {

  idMooc: string = '';
  instituciones = [false, false, false];
  proximamente = [false, false, false];
  
  moocData: TMoocData = {
    autor: [],
    category: "",
    claps: "",
    collaborators: [],
    convenio: [],
    description: "",
    idCourse: "",
    imageDescription: "",
    institucion: [],
    lessonsCount: 0,
    objetives: [],
    order: 0,
    path: "",
    phrase: {
      author: "",
      text: "",
    },
    proximamente: [],
    resourcesCount: {
      cant: 0,
      values: 0,
    },
    state: false,
    tags: [],
    time: "",
    title: "",
    videoDescription_areandina: "",
    videoDescription_istmo: "",
    videoDescription_sanmarcos: "",
  };

  Lections = new Array<TLections>();

  time: string = ''; // Valor inicial
  seconds: number = 0;
  

  newTag: string = ""; // Variable para almacenar la nueva palabra clave
  moocConvenio: boolean = false;
  actualizar: boolean = false;

  // Nueva propiedad para almacenar los objetivos de las lecciones
  learningObjectives: any[] = [];

  constructor(
    private admin: AdminService,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {

    this.idMooc = this.route.snapshot.paramMap.get('id');
    console.log('idMooc: ', this.idMooc)

    if (this.idMooc != null) {
      this.getDataMooc(this.idMooc);
      this.getDataLections(this.idMooc);
      this.actualizar = true;
      console.log('actualizar', this.actualizar)
    }
    
    // console.log(this.router.url);
    // this.rellenaDataLecciones();

  }

  submitMoocForm() {
    console.log('submut formulario.... ', this.moocData)
    // return
    
    // Llama al método del servicio para insertar el MOOC sin las lecciones
    this.admin
      .insertMooc(this.moocData, this.actualizar)
      .then((moocDocRef: any) => {

        if (!this.actualizar) {
          
          console.log("Nuevo MOOC insertado con éxito ", moocDocRef);
          Swal.fire("Nuevo MOOC insertado con éxito!");
        } else {
          
          console.log("MOOC actualizado con éxito ", moocDocRef);
          Swal.fire("MOOC actualizado con éxito!");
        }

        // Obtiene el ID del documento de MOOC recién creado
        const moocId = moocDocRef;

        this.router.navigate([`/editar-mooc/${moocId}`]);

        // Después de insertar todas las lecciones, intenta agregar el material de apoyo
        //this.addSupportMaterial();
      })
      .catch((error) => {
        console.error("Error al insertar el nuevo MOOC", error);
      });
  }

  //--------------------------------- TAG ---------------------------------------

  addTag(event: KeyboardEvent) {
    if (this.newTag.trim() !== "") {
      this.moocData.tags.push(this.newTag.trim());
      this.newTag = ""; // Limpia el campo de entrada de la nueva palabra clave
    }
    event.preventDefault(); // Evita el envío del formulario al presionar Enter
  }

  removeTag(index: number) {
    this.moocData.tags.splice(index, 1);
  }

  //--------------------------------- OBJETIVOS ---------------------------------------

  // Función para agregar un nuevo objetivo al arreglo de objetivos
  addObjective() {
    var cant_okr = this.moocData.objetives.length + 1;
    this.moocData.objetives.push({
      nameObjetive: "",
      description: "",
      okr: "OKR " + cant_okr,
    });
  }

  // Función para eliminar un objetivo del arreglo de objetivos
  removeObjective(index: number) {
    this.moocData.objetives.splice(index, 1);
  }

  //--------------------------------- AUTOR ---------------------------------------

  // Función para agregar un nuevo autor al arreglo de autores
  addAuthor() {
    this.moocData.autor.push({
      nombre: "",
      profile: "",
      photoURL: "",
      profesion: "",
      genero: "",
    });
  }

  removeAuthor(index: number) {
    this.moocData.autor.splice(index, 1);
  }


  //--------------------------------- COLABORADOR ---------------------------------------

  // Función para agregar un nuevo colaborador al arreglo de colaboradores
  addColaborador() {
    this.moocData.collaborators.push({
      name: "",
      profile: "",
      photoURL: "",
    });
  }

  removeColaborador(index: number) {
    this.moocData.collaborators.splice(index, 1);
  }

  //--------------------------------- LECCCION ---------------------------------------

  // Función para agregar una nueva lección al arreglo de lecciones

  addLeccion() {

    const idLection = this.afs.createId();
    const idActivity = this.afs.createId();
  
    const cant = this.Lections.length + 1;
    
    const nuevaLeccion: TLections = {
      description: "",
      id: idLection,
      idCourse: this.idMooc,
      learningObjectives: [],
      order: cant,
      path: "",
      title: "",
      video: {
        src_areandina: "",
        src_istmo: "",
        src_sanmarcos: "",
        title: "",
        type: "video/mp4",
      },
      activitie: {
        action: "question",
        data: {
          questionLection: [],
        },
        id: idActivity,
        idCourse: this.idMooc,
        idLesson: idLection,
        lesson: '',
        tag: 'question',
        type: 'questionLesson',
        value: 1
      },
      tempResources: [],
    };
    this.Lections.push(nuevaLeccion);
  }

  removeLeccion(index: number) {
    this.Lections.splice(index, 1);
  }


  // Dentro de tu componente FormMoocComponent
  addLearningObjective(index: number) {
    const newObjective: TLectionObjetive = {
      okr: "",
      objetive: "",
    };
    this.Lections[index].learningObjectives.push(newObjective);
  }

  // Función para eliminar un objetivo de lección
  removeLearningObjective(leccionIndex: number, objectiveIndex: number) {
    this.Lections[leccionIndex].learningObjectives.splice(objectiveIndex, 1);
  }

  //--------------------------------- ACTIVIDADES ---------------------------------------

  addLectionActivity(i: number) {
    var newQuestion: Tquestion = {
      answers: [
        {name: '', value:0},
        {name: '', value:0},
        {name: '', value:0}
      ],
      feedback: {negative:'', positive:''},
      question: '',
      tipo:''
    }
    this.Lections[i].activitie.data.questionLection.push(newQuestion);
    console.log('lecciones (add activity): ', this.Lections)
  }

  changeRaadio(i: number, q: number, r: number, event: any){
    
    setTimeout(() => {
      
      this.Lections[i].activitie.data.questionLection[q].answers[r].value = event.target.checked ? 1 : 0;
    }, 400);
    
  }

  // Función para eliminar una pregunta de la actividad de una lección
  removePreutaActivityLection(leccionIndex: number, questionIndex: number) {
    this.Lections[leccionIndex].activitie.data.questionLection.splice(questionIndex, 1);
  }


  //--------------------------------- MATERIAL DE SOPORTE ---------------------------------------

  addSupportMaterial(i: number) {

    const idReference = this.afs.createId();
    const idResourceMooc = this.afs.createId();

    // Agregar el nuevo material a la subcolección 'resources'
    const newMaterial: TResources = {
      action: "notification",
      data: {
        idReference: idReference,
        label: "",
        time: 0,
        title: "",
      },
      id: idResourceMooc,
      idCourse: this.idMooc,
      idLesson: this.Lections[i].id,
      lesson: this.Lections[i].path,
      tag: "cuepoint",
      type: "",
      value: 1,
      url: '',
      minuto: '00:00'
    };

    this.Lections[i].tempResources.push(newMaterial);

  }

  removeSupportMaterial(lectionIndex: number, index: number) {
    this.Lections[lectionIndex].tempResources.splice(index, 1);
  }

  validInstitucion(){

    this.moocData.institucion = [];
  
    if (this.instituciones[0] == true) {
      this.moocData.institucion.push('areandina');
    }
    if (this.instituciones[1] == true) {
      this.moocData.institucion.push('istmo');
    }
    if (this.instituciones[2] == true) {
      this.moocData.institucion.push('sanmarcos');
    }

  }

  validaProximamente(){

    this.moocData.proximamente = [];
  
    if (this.proximamente[0] == true) {
      this.moocData.proximamente.push('areandina');
    }
    if (this.proximamente[1] == true) {
      this.moocData.proximamente.push('istmo');
    }
    if (this.proximamente[2] == true) {
      this.moocData.proximamente.push('sanmarcos');
    }

  }

  validaGetInstitucion(){
    
      this.instituciones[0] = this.moocData.institucion.includes('areandina') ? true : false;
      this.instituciones[1] = this.moocData.institucion.includes('istmo') ? true : false;
      this.instituciones[2] = this.moocData.institucion.includes('sanmarcos') ? true : false;

      this.proximamente[0] = this.moocData.proximamente.includes('areandina') ? true : false;
      this.proximamente[1] = this.moocData.proximamente.includes('istmo') ? true : false;
      this.proximamente[2] = this.moocData.proximamente.includes('sanmarcos') ? true : false;
  }

  generaPath(){

    this.moocData.title = this.moocData.title.trim();
    var texto = this.removeAccents(this.moocData.title); // quitar las tildes
    texto = this.removeSpecialCharacters(texto); // quitar caracteres especiales
    texto = texto.toLowerCase(); // convertir a minusculas
    texto = texto.replace(/ /g, '-'); // reemplazar los espaios por guines -

    this.moocData.path = texto;
  }

  generaPathLesson(i){

    this.Lections[i].title = this.Lections[i].title.trim();
    var texto = this.Lections[i].title;
    texto = this.removeAccents(texto); // quitar las tildes
    texto = this.removeSpecialCharacters(texto); // quitar caracteres especiales
    texto = texto.toLowerCase(); // convertir a minusculas
    texto = texto.replace(/ /g, '-'); // reemplazar los espaios por guines -

    this.Lections[i].path = texto;
    this.Lections[i].activitie.lesson = texto;
  }

  removeSpecialCharacters(input: string): string {
    return input.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  removeAccents(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  getDataMooc(idMooc){

    this.admin.getCourseById(idMooc)
    .subscribe((dataCourse: any) => {
      console.log('dataCourse: ', dataCourse[0])
      if (dataCourse[0] != undefined) {
        
        this.moocData = dataCourse[0];

        setTimeout(() => {
          this.validaGetInstitucion();
        }, 700);
      }
    });

  }

  getDataLections(moocId){

    this.admin.getLessonsByidCourse(moocId)
      .subscribe((lections: any) => {
        this.Lections = lections;
        console.log('lections: ', lections)
      });
    
  }

  openTab(tabId) {
    // Obtener todas las pestañas
    var tabs = document.querySelectorAll('.nav-link');

    // Recorrer todas las pestañas y quitar la clase 'active'
    tabs.forEach(function(tab) {
      tab.classList.remove('active');
    });

    // Agregar la clase 'active' a la pestaña seleccionada
    document.getElementById(tabId + '-tab').classList.add('active');

    // Mostrar el contenido de la pestaña seleccionada
    var tabContent = document.getElementById('myTabContent').querySelector('.tab-pane.active');
    tabContent.classList.remove('active');
    tabContent.classList.remove('show');
    document.getElementById(tabId).classList.add('active');
    document.getElementById(tabId).classList.add('show');
  }

  formatTime(i: number, v: number) {
    // Asegúrate de que el input tenga el formato correcto MM:SS
    const timePattern = /^\d{1,2}:\d{2}$/;

    const time = this.Lections[i].tempResources[v].minuto;
    
    if (time.match(timePattern)) {
      const [minutes, seconds] = time.split(':').map(Number);
      var segundos = (minutes * 60) + seconds;
      this.Lections[i].tempResources[v].data.time = segundos;
    } else {
      var segundos = 0;
      this.Lections[i].tempResources[v].data.time = segundos;
    }
  }

  guardarLecciones(){
    console.log(this.Lections)

    const moocId = this.idMooc;

    // Itera sobre las lecciones y agrega cada una a la subcolección 'lections'
    this.Lections.forEach((leccion) => {
      this.admin
        .insertLeccion(moocId, leccion)
        .then(() => {
          console.log("Lección insertada con éxito");

          // ahora vamos a insertar la actividad (en mooc resources)
          this.admin.insertMaterial(moocId, leccion.activitie)
            .then((result) => {
              console.log('actividad insertada')
            }).catch((err) => {
              console.log('error al insertar actividad ', err)
            });

          // aqui vamos a insertar los materiales de apoyo 
          leccion.tempResources.forEach((resource) => {
            this.admin.insertMaterial(moocId, resource)
              .then((result) => {
                console.log('recurso en mooc insertado')
              }).catch((err) => {
                console.log('no se inserto el recurso en mooc')
              });

            var dateResource = {
              id: resource.data.idReference,
              srcSource: resource.type == 'iframe' || resource.type == 'lectura' ? resource.data.idReference+'/'+resource.url : resource.url,
              titleResource: resource.data.title,
              typeResource: resource.type
            }
            // aqui vamos a insertar en la coleccion de recursos 
            this.admin.insertResource(dateResource)
            .then((result) => {
              console.log('se inserto el recurso en recursos')
            }).catch((err) => {
              console.log('no se inserto el recurso en recursos')
            });
          });
          
        })
        .catch((error) => {
          console.error("Error al insertar la lección", error);
        });
    });

    setTimeout(() => {
      console.log("Lecciones actualizadas con éxito!");
      Swal.fire("Lecciones actualizadas con éxito!");
    }, 2000);

  }

  changeTipoPregunta(l, p){
    
    setTimeout(() => {

      if (this.Lections[l].activitie.data.questionLection[p].tipo == 'fov') {
        this.Lections[l].activitie.data.questionLection[p].answers.splice(2, 1);
      } else {
        if (this.Lections[l].activitie.data.questionLection[p].answers[2] == undefined) {
          this.Lections[l].activitie.data.questionLection[p].answers.push({name:'', value:0});
        }
      }
      
      console.log(this.Lections[l].activitie.data.questionLection[p].tipo)
      console.log('respuestas: ', this.Lections[l].activitie.data.questionLection[p].answers)
    }, 200);
  }

  rellenaDataLecciones(){

    this.Lections = [
      {
          "description": "La cooperación ha surgido como un proceso histórico en el que se ha configurado como una importante herramienta para ser ejercida tanto por actores públicos como privados. Busca contribuir a la solución de problemas locales y, a su vez, responder a los desafíos y amenazas emergentes de las dinámicas de un mundo cada vez más interdependiente y transnacional. Esta lección presenta un marco de referencia histórico, teórico y conceptual para comprender la cooperación y su importancia por lo cual, encontrarán una nube de palabras y un video de preguntas sobre las referencias teórico-conceptuales en la cooperación internacional. El objetivo es conocer el marco de referencia histórico, teórico y conceptual sobre la cooperación internacional y la cooperación internacional para el desarrollo dada su evolución e importancia actual.",
          "id": "SOlvMMV9LjqyEPBlx1EI",
          "idCourse": "V1YZjgpl6808epO5Sf6K",
          "learningObjectives": [
              {
                  "okr": "El estudiante conoce la evolución histórica de la cooperación internacional y su importancia.",
                  "objetive": "Conocer el marco de referencia histórico, teórico y conceptual sobre la cooperación internacional y la cooperación internacional para el desarrollo dada su evolución."
              }
          ],
          "order": 1,
          "path": "como-se-entiende-la-cooperacion-internacional",
          "title": "¿Cómo se entiende la cooperación internacional?",
          "video": {
              "src_areandina": "https://player.vimeo.com/progressive_redirect/playback/912232014/rendition/1080p/file.mp4?loc=external&signature=86566864434d2be12cdb3444ce2a829e338523be113804b6afb57ba6d13239cb",
              "src_istmo": "",
              "src_sanmarcos": "",
              "title": "Video de la lección 1",
              "type": "video/mp4"
          },
          "activitie": {
              "action": "question",
              "data": {
                  "questionLection": [
                      {
                          "answers": [
                              {
                                  "name": "Un régimen internacional.",
                                  "value": 1
                              },
                              {
                                  "name": "Una convención internacional. ",
                                  "value": 0
                              },
                              {
                                  "name": "Una organización internacional en la que convergen intereses. ",
                                  "value": 0
                              }
                          ],
                          "feedback": {
                              "negative": "Tu respuesta no es correcta, Ten presente que, dentro de las formas instituciones internacionales, una convención internacional son normas implícitas como la costumbre; es decir, no estás escritas. Por otra parte, las organizaciones son una forma de institución internacional que goza de burocracia y presupuesto. El régimen representa los principios, normas, reglas y proceso de toma de decisiones en un tema específico de la agenda internacional. En esto radica que una cosa se entiende la carta de las Naciones Unidas o el GATT como régimen internacional y otra, sus sedes físicas como organizaciones cuyas funciones se basan en los regímenes constitutivos.  ",
                              "positive": "¡Correcto! Las instituciones internacionales pueden ser convenciones, regímenes y organizaciones internacionales. Sin embargo, las convenciones son tan solo normas implícitas, es decir, que no están positivizadas como la costumbre. Los regímenes, principalmente son entendidos como normas, reglas y principios explícitos en temas específicos de la agenda internacional como puede ser la cooperación internacional para el desarrollo. "
                          },
                          "question": "Como institución internacional, la cooperación internacional, así como el sistema de derechos humanos o la lucha contra las drogas puede ser entendida como: ",
                          "tipo": "multiple"
                      },
                      {
                          "answers": [
                              {
                                  "name": "FALSO",
                                  "value": 0
                              },
                              {
                                  "name": "VERDADERO.",
                                  "value": 1
                              }
                          ],
                          "feedback": {
                              "negative": "Tu respuesta no es correcta. Entender la cooperación solamente como armonía de intereses en entender que solo se puede cooperar y negociar con los amigos lo que puede presentar una visión romántica puesto que, en muchas ocasiones, están acciones también tiene lugar con los enemigos con los que no existe tal armonía. ",
                              "positive": "¡Así es! Como se señaló, la cooperación solo puede tener lugar en situaciones que contienen una mezcla de intereses contrapuestos y complementarios, en donde los actores ajustan su comportamiento a las preferencias reales o previstas de los demás."
                          },
                          "question": "La cooperación internacional tiene lugar en escenario de armonía y discordia de intereses.",
                          "tipo": "fov"
                      }
                  ]
              },
              "id": "ZOagvxT8ivBbbq20pHNx",
              "idCourse": "V1YZjgpl6808epO5Sf6K",
              "idLesson": "SOlvMMV9LjqyEPBlx1EI",
              "lesson": "como-se-entiende-la-cooperacion-internacional-",
              "tag": "question",
              "type": "questionLesson",
              "value": 1
          },
          "tempResources": [
              {
                  "action": "notification",
                  "data": {
                      "idReference": "qFVVcciE7UjSQideBjzc",
                      "label": "VIDEOPREGUNTAS",
                      "time": 90,
                      "title": "Torneo de dilema del prisionero"
                  },
                  "id": "cj20mh6NEscX21TQtyWj",
                  "idCourse": "V1YZjgpl6808epO5Sf6K",
                  "idLesson": "SOlvMMV9LjqyEPBlx1EI",
                  "lesson": "como-se-entiende-la-cooperacion-internacional",
                  "tag": "cuepoint",
                  "type": "iframe",
                  "value": 1,
                  "url": "index.html",
                  "minuto": "01:30"
              },
              {
                  "action": "notification",
                  "data": {
                      "idReference": "ZBNKqCZz9JoSp7VdRzBb",
                      "label": "NUBE DE PALABRAS",
                      "time": 219,
                      "title": "Cómo entender la cooperación internacional"
                  },
                  "id": "GIVvmrjOFphWFSpPmxbu",
                  "idCourse": "V1YZjgpl6808epO5Sf6K",
                  "idLesson": "SOlvMMV9LjqyEPBlx1EI",
                  "lesson": "como-se-entiende-la-cooperacion-internacional",
                  "tag": "cuepoint",
                  "type": "iframe",
                  "value": 1,
                  "url": "index.html",
                  "minuto": "03:39"
              }
          ]
      },
      {
          "description": "A partir de la creación del sistema institucional de cooperación internacional al desarrollo (1950-1960), esta se ha transformado y adaptado de acuerdo con el paradigma dominante en materia de desarrollo en cada etapa. Así mismo, su evolución histórica y el surgimiento de nuevos actores más allá de los Estados han configurado una amalgama de fuentes y tipologías que presentan escenarios de oportunidad para la formulación de proyectos en pro de una intervención eficaz y eficiente en materia de desarrollo y frente a los problemas por resolver. \t",
          "id": "VZywoQrFJTuT5TTKE2FS",
          "idCourse": "V1YZjgpl6808epO5Sf6K",
          "learningObjectives": [
              {
                  "okr": "El estudiante entiende la cooperación internacional como un sistema identificando actores, principios, fuentes (instrumentos), fondos, modalidades y tipos de cooperación internacional, entre otros; e identifica las principales modalidades y tipos de cooperación internacional, así como sus criterios claves.",
                  "objetive": "Comprender la cooperación internacional como un sistema dado los actores participantes y las modalidades existentes."
              }
          ],
          "order": 2,
          "path": "el-sistema-de-cooperacion-internacional-modalidades-y-criterios",
          "title": "El sistema de cooperación internacional: modalidades y criterios",
          "video": {
              "src_areandina": "https://player.vimeo.com/progressive_redirect/playback/912232033/rendition/1080p/file.mp4?loc=external&signature=6bb6393a19dfaecb3e3a6631530fd16101848bcb77e8ba7cbba42ff094a514de",
              "src_istmo": "",
              "src_sanmarcos": "",
              "title": "El sistema de cooperación internacional: modalidades y criterios",
              "type": "video/mp4"
          },
          "activitie": {
              "action": "question",
              "data": {
                  "questionLection": [
                      {
                          "answers": [
                              {
                                  "name": "Los 2000 con los Objetivos de Desarrollo del Milenio (2000).",
                                  "value": 0
                              },
                              {
                                  "name": "Las décadas de 1950 y 1960.",
                                  "value": 1
                              },
                              {
                                  "name": "2015 con los Objetivos de Desarrollo Sostenible.",
                                  "value": 0
                              }
                          ],
                          "feedback": {
                              "negative": "Tu respuesta no es correcta. Los objetivos de Desarrollo del Milenio (2000-2015) y los Objetivos de Desarrollo Sostenible (2015-2030) establecen objetivos dentro de los paradigmas de desarrollo vigente como son, el desarrollo sostenible y el desarrollo humano. ",
                              "positive": "¡Correcto! Entre los años cincuenta y sesenta se asistió a un período posbélico cuyo escenario se caracterizó por cambios trascendentales en el panorama internacional que contribuyeron a la generación de escenarios propicios para el inicio de actividades de cooperación. "
                          },
                          "question": "De acuerdo con la lectura, la creación del sistema institucional de la cooperación internacional al desarrollo tuvo lugar en: ",
                          "tipo": "multiple"
                      },
                      {
                          "answers": [
                              {
                                  "name": "Verdadero",
                                  "value": 1
                              },
                              {
                                  "name": "Falso",
                                  "value": 0
                              }
                          ],
                          "feedback": {
                              "negative": "Tu respuesta no es correcta. La cooperación técnica como su nombre lo indica, se caracteriza por la transferencia de conocimiento, saberes y habilidades, entre otros, sobre el saber cómo (“Know How”). ",
                              "positive": "¡Correcto! La cooperación técnica es una tipología de cooperación que responde al criterio de la naturaleza de los recursos.     Otra tipología es la cooperación financiera. "
                          },
                          "question": "El propósito de cooperación técnica es la transferencia de tecnologías, conocimiento, habilidades, entre otros. ",
                          "tipo": "fov"
                      }
                  ]
              },
              "id": "Yv6CnjK90wjFtlIRTy7f",
              "idCourse": "V1YZjgpl6808epO5Sf6K",
              "idLesson": "VZywoQrFJTuT5TTKE2FS",
              "lesson": "el-sistema-de-cooperacion-internacional-modalidades-y-criterios",
              "tag": "question",
              "type": "questionLesson",
              "value": 1
          },
          "tempResources": [
              {
                  "action": "notification",
                  "data": {
                      "idReference": "7pjd7q2qIXk8YrCyTKML",
                      "label": "ENLACE EXTERNO",
                      "time": 94,
                      "title": "Una introducción a la cooperación internacional al desarrollo"
                  },
                  "id": "Qb0TWimhwFY8SJ2dMjmd",
                  "idCourse": "V1YZjgpl6808epO5Sf6K",
                  "idLesson": "VZywoQrFJTuT5TTKE2FS",
                  "lesson": "el-sistema-de-cooperacion-internacional-modalidades-y-criterios",
                  "tag": "cuepoint",
                  "type": "link",
                  "value": 1,
                  "url": "https://publicaciones.unirioja.es/ojs/index.php/redur/article/view/4115/3365",
                  "minuto": "01:34"
              },
              {
                  "action": "notification",
                  "data": {
                      "idReference": "uwYSd6OypRD0e92UVDc7",
                      "label": "EMPAREJAMIENTO",
                      "time": 294,
                      "title": "Sistema de cooperación internacional para el desarrollo"
                  },
                  "id": "umFEOZDA0GQg4JUzseGg",
                  "idCourse": "V1YZjgpl6808epO5Sf6K",
                  "idLesson": "VZywoQrFJTuT5TTKE2FS",
                  "lesson": "el-sistema-de-cooperacion-internacional-modalidades-y-criterios",
                  "tag": "cuepoint",
                  "type": "iframe",
                  "value": 1,
                  "url": "story.html",
                  "minuto": "04:54"
              }
          ]
      }
    ]
  }


}
