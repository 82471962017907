import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../core/auth/user.model';
import { AuthService } from '../core/auth/auth.service';
import { DatabaseService } from '../core/db/database.service';
import { take } from 'rxjs/operators';
import { ConfigService } from '@app/core/services/config.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class CertificadoService {

  constructor(public afs: AngularFirestore,private db: DatabaseService, public auth: AuthService, public config: ConfigService) { }

  getUser(idUser): Observable<User> {
    let getUser = this.db.doc<User>(`users/${idUser}`)
    return getUser.valueChanges()
  }

  getCertify(doc: string): Observable<any> {
    const getUser = this.db.doc$(`certify/${doc}`)
    return getUser
  }
/*   getCertify(doc: string): Observable<any>{
    console.log(doc)
    return this.afs.collection('certify').doc(''+doc+'').valueChanges().pipe(take(1));

  } */

  getCertifiByUser(uid: string): Observable<any[]> {
    // this.db.inspectCol('certify')
    const certificates = this.db.col('certify', ref => ref.where('uid', '==', `${uid}`))
    return certificates.valueChanges()
  }

  getEnrollment(idUser, idCourse): Observable<any> {
    const enrollUser = this.db.col('enrollment', ref => ref.where('idUser', '==', `${idUser}`).where('courseId', '==', `${idCourse}`))
    return enrollUser.valueChanges()
  }

  updateCertify(certify, uid) {

    const certifyReference = this.db.doc(`certify/${certify.id}`)
    const current = certify.downloads ? certify.downloads : []

    current.push({
      time: new Date(),
      user: uid ? uid : 'error',
      institucion: this.config.checkSchool()['name']
    })

    certifyReference.update(
      { downloads: current }
    )
  }

  getIsConvenio(idCourse): Observable<any> {
    return this.db.doc(`moocs/${idCourse}`).valueChanges();
  }
  
  getCertifyEmpty(id: string): Observable<any> {
    const certify = this.db.col('certify', ref => ref.where('courseName', '==', ``))
    return certify.valueChanges()
  }

  

  updateCertifyCorrected(courseId: any, name: string) {
    const certifyRef = this.afs.collection('certify', ref => ref.where('courseId', '==', courseId));
  
    console.error("Comienza la consulta ");
    return certifyRef.get().toPromise().then(querySnapshot => {
      
      console.error("Terminé el get ");
      
      const batch = this.afs.firestore.batch();
      let contador = 0;
      querySnapshot.forEach(doc => {
        contador += 1;
        const docRef = this.afs.firestore.collection('certify').doc(doc.id);
        console.log("Actualizado:::: " + contador + " - " + doc + " y tengo un id -> " + doc.id);
        batch.update(docRef, { courseName: name });
      });
  
      return batch.commit();
    }).catch(error => {
      console.error("Error actualizando los documentos: ", error);
      throw error;
    });
  }

}
