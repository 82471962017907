export const environment = {
	appName: 'MOOC',
	envName: 'DEV',
	production: true,
	path: 'https://contenidos.areandina.edu.co/repo/mooc/repo/',
	firebaseConfig: {
				 apiKey: "AIzaSyBucBlApAh7f6IjW1khbUABf1wNi9NB4H4",
				 authDomain: "moocs-4355c.firebaseapp.com",
				 databaseURL: "https://moocs-4355c.firebaseio.com",
				 projectId: "moocs-4355c",
				 storageBucket: "moocs-4355c.appspot.com",
				 messagingSenderId: "445742716545",
				 points: true, 

		//apiKey: "AIzaSyByBb-qwXyKsYi7aWGRBt5KDefuoEwlarQ",
		//authDomain: "mirror-mooc-233515.firebaseapp.com",
		//databaseURL: "https://mirror-mooc-233515.firebaseio.com",
		//projectId: "mirror-mooc-233515",
		//storageBucket: "mirror-mooc-233515.appspot.com",
		//messagingSenderId: "543639865241",
		//appId: "1:543639865241:web:3aab40a055624e1106f508",
		//measurementId: "G-P1J551B0QT",
		//points: false,

	}
};
