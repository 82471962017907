
import { Component, OnInit, Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';
import { ConfigService } from '@app/core/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
/* @Directive({
  selector: '[scrollSpy]'
}) */

@Component({
  selector: 'mooc-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  FAQS: any[] = [];
  AllFAQS: any[] = [];
  /*   currentSection = 'section1'; */

  constructor(private configSrvice: ConfigService, private _el: ElementRef, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    console.log('footer...');

    this.getFaq('init');

    // this.configSrvice.updateProgresEnrollMasive();

    // this.configSrvice.getCertifyAll();

  }

  getFaq(param) {
    this.AllFAQS = [];
    this.FAQS = [];
    this.configSrvice.getFaq().subscribe((res:any) => {
      res.map((item, i) => {
        let exist = false;
        if (this.FAQS.length < 1) {
          this.FAQS.push({
            tema: item['tema'],
            data: [item]
          })
        } else {
          this.FAQS.map((faq, j) => {
            if (faq.tema == item['tema']) {
              exist = true;
              this.FAQS[j].data.push(item)
            }
          })
          if (!exist) {
            this.FAQS.push({
              tema: item['tema'],
              data: [item]
            })
          }
        }
      })
      if (param == 'all') { 
        this.AllFAQS = res.map((faq) => ({
          ...faq,
          sanitizedAnswer: this.sanitizer.bypassSecurityTrustHtml(faq.answer),
        }));
      } else if (param == 'init') {
        this.AllFAQS = this.FAQS[0].data.map((faq: { answer: string }) => ({
          ...faq,
          sanitizedAnswer: this.sanitizer.bypassSecurityTrustHtml(faq.answer),
        }));
      }
    })
  }


  changeTheme(i) {
    this.AllFAQS = [];
    if (i == 'all') {
      this.getFaq('all');
    } else {
      this.AllFAQS = this.FAQS[i].data
    }
  }
}
